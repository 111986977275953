<template>
  <base-section
    id="project"
    max-width="1024"
    class="mx-auto"
  >
    <v-container>
      <base-subheading
        :title="title"
        tag="h1"
        space="0"
      />
      <div
        v-if="!!categories.length >0"
        class="text-subtitle-1"
        style="margin-bottom: 48px"
      >
        {{ categories.join(" / ") }}
      </div>
      <base-img
        v-if="!!cover"
        :height="$vuetify.breakpoint.mdAndUp ? 500 : 250"
        :src="cover ? getFileByHash(cover) : ''"
        class="mb-6 mb-md-12"
      />

      <v-row v-if="!!description">
        <v-col
          cols="12"
          md="12"
        >
          <base-body space="12">
            <div v-html="description" />
          </base-body>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-for="(photo,i) in photos"
          :key="i"
          class="d-flex child-flex"
          cols="6"
          md="4"
          sm="6"
        >
          <v-card
            flat
            tile
            class="d-flex"
          >
            <base-img
              :src="photo ? getFileByHash(photo):''"
              aspect-ratio="1"
              class="grey lighten-2"
              style="cursor: pointer"
              @click="index = i"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  />
                </v-row>
              </template>
            </base-img>
            <vue-gallery-slideshow
              :images="renderedFiles"
              :index="index"
              @close="index = null"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import axios from 'axios'
  import VueGallerySlideshow from 'vue-gallery-slideshow'

  export default {
    name: 'Project',

    components: {
      VueGallerySlideshow,
    },

    data: () => ({
      cover: '',
      title: 'Chargement ...',
      categories: [],
      description: '',
      photos: [],
      index: null,
    }),

    computed: {
      renderedFiles () {
        const hashs = this.photos.filter(item => {
          return !!item
        })

        const final = []
        for (var i = 0; i < hashs.length; i++) {
          final.push(this.getFileByHash(hashs[i]))
        }

        return final
      },
    },
    mounted () {
      var pageURL = window.location.href
      var id = pageURL.substr(pageURL.lastIndexOf('/') + 1)

      axios.get('https://www.ironwood.ch/cms/public/ironwood/items/gallery_article/' + id + '?fields=title,content,cover_image.private_hash,files.directus_files_id,categories.gallery_category_id').then((response) => {
        if (response.status === 200 && typeof response.data !== 'undefined' && typeof response.data.data !== 'undefined') {

          const row = response.data.data

          this.photos = []
          for (let j = 0; j < row.files.length; j++) {
            this.photos.push('')
            axios.get('https://www.ironwood.ch/cms/public/ironwood/files/' + row.files[j].directus_files_id + '?fields=private_hash').then((response) => {
              if (response.status === 200 && typeof response.data !== 'undefined' && typeof response.data.data !== 'undefined') {
                this.photos.splice(j, 1, response.data.data.private_hash)
              }
            })
          }

          for (let j = 0; j < row.categories.length; j++) {
            axios.get('https://www.ironwood.ch/cms/public/ironwood/items/gallery_category/' + row.categories[j].gallery_category_id + '?fields=name').then((response) => {
              if (response.status === 200 && typeof response.data !== 'undefined' && typeof response.data.data !== 'undefined') {
                this.categories.push(response.data.data.name)
              }
            })
          }

          this.cover = row.cover_image.private_hash
          this.title = row.title
          this.description = row.content
        }
      })
    },

    methods: {
      getFileByHash (hash) {
        return 'https://www.ironwood.ch/cms/public/ironwood/assets/' + hash
      },
    },
  }
</script>
